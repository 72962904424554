import { email } from 'components/FormFields/validations';
import { Component } from 'react';
import AutosizeInput from 'react-input-autosize';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { parseEmailAddress, splitBySpecialChars } from '../utils';
import Styles from './EmailList.module.scss';

const renderInput = ({ addTag, onChange, value, ...rest }) => (
  <AutosizeInput type="text" onChange={onChange} value={value} {...rest} />
);

// Email list item separators: Enter (13), Semicolon (186), and/or Comma (188)
const defaultReturnKeys = [13, 186, 188];

class EmailList extends Component {
  constructor(props) {
    super(props);
    this.state = { tags: [], tag: '' };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      tags: nextProps.value,
    });
  }

  onChange = (tags) => {
    const { added, malformed, duplicated } = tags.reduce(
      (result, tag) => {
        const parsed = parseEmailAddress(tag);
        if (parsed && !email(parsed.address)) {
          const compareParsedAddress = (email) =>
            email.toLowerCase() === parsed.address.toLowerCase();
          if (
            this.props.existingEmails.some(compareParsedAddress) ||
            result.duplicated.some(compareParsedAddress)
          ) {
            return { ...result, duplicated: [...result.duplicated, parsed.address] };
          } else {
            return { ...result, added: [...result.added, tag] };
          }
        } else {
          return { ...result, malformed: [...result.malformed, tag] };
        }
      },
      { added: [], malformed: [], duplicated: [] },
    );

    this.setState({ tags: added, malformed, duplicated }, () =>
      this.props.onChange(this.state.tags),
    );
  };

  render() {
    const { value, classes, existingEmails, ...rest } = this.props;

    return (
      <div className={Styles.overrides}>
        <TagsInput
          {...rest}
          addOnPaste
          onlyUnique
          addOnBlur
          addKeys={defaultReturnKeys}
          ref="tagsInput"
          pasteSplit={splitBySpecialChars}
          onChange={this.onChange}
          value={value || []}
          inputValue={this.state.tag}
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: 'Add an email',
            autoFocus: rest.autoFocus,
          }}
          renderInput={renderInput}
        />
        {!!this.state.malformed?.length && (
          <small className="text-red01">
            The following addresses couldn't be added because they are not well-formed:
            <ul>
              {this.state.malformed.map((tag, index) => (
                <li key={`${tag}-${index}`}>{tag}</li>
              ))}
            </ul>
          </small>
        )}
        {!!this.state.duplicated?.length && (
          <small className="text-red01">
            The following addresses couldn't be added because they have already been invited:
            <ul>
              {this.state.duplicated.map((tag, index) => (
                <li key={`${tag}-${index}`}>{tag}</li>
              ))}
            </ul>
          </small>
        )}
      </div>
    );
  }
}

EmailList.displayName = 'EmailList';
export default EmailList;

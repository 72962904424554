import React from 'react';
import GenericModal from 'components/GenericModal';
import StatusAndRangeSelector from './StatusAndRangeSelector'; // Adjust the import path as needed

const renderBodyContent = () => (
  <p>
    <b className="text-red01">STOP!!</b> Exporting the "for Team" PDF will disable all ability to
    modify or add additional comments. Only selection committee comments will be available to add
    once this PDF is generated.
  </p>
);

const renderBodyContentMultiple = () => (
  <div className="alert alert-warning text-justify mt-3">
    <b className="text-red01">STOP!!</b> Exporting the "for Team" PDF will disable all ability to
    modify or add additional comments for selected teams. Only selection committee comments will be
    available to add once these PDFs are generated.
  </div>
);

const ConfirmPrintPDFForTeamsModal = ({
  show,
  onCancel,
  onConfirm,
  multiple = false,
  isForTeams = false,
  showStatusAndRangeSelector = false,
  phaseFinalRank,
  pdfTeamRange,
  totalEvaluations,
  pdfTeamStatusCheckboxes,
  handleOnChangePDFTeamStatusCheckbox,
  setPdfTeamRange,
}) => {
  return (
    <GenericModal
      danger={isForTeams}
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name={'confirm-print-pdf-for-teams'}
      title={'Export Team Results PDF'}
      body={
        <>
          {showStatusAndRangeSelector && (
            <>
              <p>Choose the status of the teams you would like to include in the PDF.</p>
              <StatusAndRangeSelector
                phaseFinalRank={phaseFinalRank}
                pdfTeamRange={pdfTeamRange}
                totalEvaluations={totalEvaluations}
                pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
                handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
                setPdfTeamRange={setPdfTeamRange}
              />
            </>
          )}
          {isForTeams && (multiple ? renderBodyContentMultiple() : renderBodyContent())}
        </>
      }
      cancelButton={'Cancel'}
      confirmButton={'Continue'}
    />
  );
};

export default ConfirmPrintPDFForTeamsModal;

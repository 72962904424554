import { Button, Modal } from '@valid-eval/shared-react-components';

type GenericModalProps = {
  show?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  name?: string;
  title?: string;
  body?: React.ReactNode;
  cancelButton?: string | null;
  confirmButton?: string | null;
  className?: string;
  size?: 'sm' | 'lg' | 'xl' | 'md';
  isButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;
  fullscreen?: string;
  footerExtraContent?: React.ReactNode;
  danger?: boolean;
};

const GenericModal = ({
  show = false,
  onCancel,
  onConfirm,
  name,
  title,
  body,
  cancelButton = 'Cancel',
  confirmButton = 'Confirm',
  className = '',
  size = 'lg',
  isButtonDisabled = false,
  isCancelButtonDisabled = false,
  fullscreen = '',
  danger = false,
  ...props
}: GenericModalProps) => {
  const { footerExtraContent } = props;

  const headerClassName = danger ? 'bg-red02 text-white' : undefined;
  const closeButtonVariant = danger ? 'white' : undefined;
  const confirmButtonVariant = danger ? 'danger' : 'success';

  return (
    <Modal
      id={`${name}-modal`}
      show={show}
      onHide={onCancel}
      className={className}
      // @ts-ignore
      size={size}
      fullscreen={fullscreen}
    >
      <Modal.Header closeButton className={headerClassName} closeVariant={closeButtonVariant}>
        <Modal.Title>
          <small>{title}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {(cancelButton || confirmButton || footerExtraContent) && (
        <Modal.Footer>
          {footerExtraContent ? footerExtraContent : null}
          {cancelButton && (
            <Button
              id={`${name}-modal-cancel`}
              onClick={onCancel}
              variant={'link'}
              disabled={isCancelButtonDisabled}
            >
              {cancelButton}
            </Button>
          )}
          {confirmButton && (
            <Button
              type={'submit'}
              variant={confirmButtonVariant}
              id={`${name}-modal-confirm`}
              onClick={onConfirm}
              disabled={isButtonDisabled}
            >
              {confirmButton}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GenericModal;

import { ListGroup, OptionValue } from '@valid-eval/shared-react-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { exportPDFMultiple } from 'data/actions/events';
import { error as notifyError } from 'data/actions/notifications';
import { createAsyncReport } from 'data/actions/reports';
import {
  getEvaluationsCountForSelectedPhase,
  getEvent,
  getIsLoadingEvaluations,
  getSelectedPhase,
} from 'data/reducers';
import withRouter from 'routes/withRouter';

import Loading from 'components/Loading';
import ConfirmPrintPDFForTeamsModal from 'screens/app/results/ConfirmPrintPDFForTeamsModal';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';
import ReportsListItem from './ReportsListItem';
import TeamSelectorModal from './TeamSelectorModal';

type OwnProps = {
  params: {
    event_id: string;
  };
};

const connector = connect(
  (state: any, ownProps: OwnProps) => ({
    eventId: ownProps.params.event_id,
    event: getEvent(state, ownProps.params.event_id)?.toJS?.(),
    phase: getSelectedPhase(state, ownProps)?.toJS?.(),
    // @ts-ignore
    totalEvaluations: getEvaluationsCountForSelectedPhase(state, ownProps),
    loading: getIsLoadingEvaluations(state),
  }),
  {
    createAsyncReport,
    exportPDFMultiple,
    notifyError,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PDFReportsProps = PropsFromRedux & OwnProps;
type PDFReportName = 'pdf_for_teams' | 'pdf_for_selection_committee' | 'pdf_for_judge_panel';

const PDFReports = ({
  event,
  exportPDFMultiple,
  phase,
  notifyError,
  totalEvaluations,
  loading,
}: PDFReportsProps) => {
  const { t } = useTranslation();
  const isQualitativeMode = event.judging_ux_mode === 'qualitative_only_mode';
  const [confirmPrintPDFForTeamsModal, setConfirmPrintPDFForTeamsModal] = useState(false);
  const [confirmPDFForJudgePanel, setConfirmCPDFForJudgePanel] = useState(false);
  const [selectedReport, setSelectedReport] = useState<PDFReportName | null>(null);
  const [isSinglePDF, setIsSinglePDF] = useState(true);
  const [showBoxPlot, setShowBoxPlot] = useState(isTeamLead() && isQualitativeMode ? false : true);

  const [pdfTeamRange, setPdfTeamRange] = useState<{
    first: number | string;
    last: number | string;
  }>({ first: 1, last: 1 });
  const [pdfTeamStatusCheckboxes, setPdfTeamStatusCheckboxes] = useState(
    Object.values(phase.status_framework).reduce<{ [key: string]: boolean }>((acc, status: any) => {
      if (status.active) acc[String(status.value)] = true;
      return acc;
    }, {}),
  );

  const reportItems = [
    {
      reportName: 'pdf_for_teams',
      title: 'As displayed to Teams',
      description: 'PDF Results for teams',
      disabled: isNavigator() || (isTeamLead() && isQualitativeMode),
    },
    {
      reportName: 'pdf_for_judge_panel',
      title: 'As displayed to Judge Panel',
      description: 'PDF Results for judge panel',
    },
    {
      reportName: 'pdf_for_selection_committee',
      title: 'As displayed to Selection Committee',
      description: 'PDF Results for selection committee',
      disabled: isTeamLead() && isQualitativeMode,
    },
  ];

  const PDF_REPORT_FLAGS = useMemo(
    () => ({
      pdf_for_teams: {
        isUnredacted: false,
        displayDetails: false,
        isJudgePanel: false,
        isSinglePDF: false,
      },
      pdf_for_selection_committee: {
        isUnredacted: true,
        displayDetails: true,
        isJudgePanel: false,
        isSinglePDF: false,
      },
      pdf_for_judge_panel: {
        displayDetails: false,
        isJudgePanel: true,
        isUnredacted: !showBoxPlot,
        isSinglePDF: isSinglePDF,
      },
    }),
    [isSinglePDF, showBoxPlot],
  );

  useEffect(() => {
    setPdfTeamRange({ ...pdfTeamRange, last: totalEvaluations });
  }, [totalEvaluations]);

  // PDFs reports
  function handleOnChangePDFTeamStatusCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setPdfTeamStatusCheckboxes({ ...pdfTeamStatusCheckboxes, [e.target.value]: e.target.checked });
  }

  function handleClickPDFReport(reportName: PDFReportName) {
    setSelectedReport(reportName);

    switch (reportName) {
      case 'pdf_for_teams':
        setConfirmPrintPDFForTeamsModal(true);
        break;
      case 'pdf_for_judge_panel':
        setConfirmCPDFForJudgePanel(true);
        break;
      case 'pdf_for_selection_committee':
        setConfirmPrintPDFForTeamsModal(true);
        break;
    }
  }

  async function confirmPDFReport(reportName: PDFReportName, teamIds?: string[]) {
    let statuses, first, last;
    const payload: any = {
      eventId: event.id,
      eventName: event.name,
      phaseId: phase.id,
      phaseName: phase.name,
      url: organizerNav.printScoresAndHeatmap(
        event.id,
        ':evaluation_id',
        PDF_REPORT_FLAGS[reportName].isUnredacted,
        PDF_REPORT_FLAGS[reportName].displayDetails,
        PDF_REPORT_FLAGS[reportName].isJudgePanel,
        PDF_REPORT_FLAGS[reportName].isSinglePDF,
      ),
    };

    if (phase.final_rank) {
      first = pdfTeamRange.first;
      last = pdfTeamRange.last;
      if (!first || !last || first > last) {
        notifyError(t('reports.pdfs.wrong_range'));
        return;
      }
    } else {
      if (Object.values(pdfTeamStatusCheckboxes).every((checked) => !checked)) {
        notifyError(t('reports.pdfs.empty_status'));
        return;
      }
      statuses = Object.entries(pdfTeamStatusCheckboxes)
        .filter(([_, checked]) => checked)
        .map(([status]) => status);
      payload.team_ids = teamIds;
    }

    payload.first = first;
    payload.last = last;
    payload.statuses = statuses;

    const result = await exportPDFMultiple(payload);

    // @ts-ignore
    if (result?.error && result?.payload?.response?.error)
      // @ts-ignore
      notifyError(result.payload.response.error);
  }

  function handleCancelPDFForTeamsReport() {
    setConfirmPrintPDFForTeamsModal(false);
  }

  function handleConfirmPDFForTeamsReport() {
    setConfirmPrintPDFForTeamsModal(false);
    confirmPDFReport('pdf_for_teams');
  }

  function handleConfirmForSelectionCommittee() {
    setConfirmPrintPDFForTeamsModal(false);
    confirmPDFReport('pdf_for_selection_committee');
  }

  function handleConfirmTeamFilesReport(teams: OptionValue[]) {
    const teamIds = teams.map((team) => team.value);
    confirmPDFReport('pdf_for_judge_panel', teamIds);
    setConfirmCPDFForJudgePanel(false);
  }

  function handleCancelTeamFilesReport() {
    setConfirmCPDFForJudgePanel(false);
  }

  function getOnConfirmFunction() {
    switch (selectedReport) {
      case 'pdf_for_teams':
        return handleConfirmPDFForTeamsReport;
      case 'pdf_for_selection_committee':
        return handleConfirmForSelectionCommittee;
      default:
        return () => {};
    }
  }

  function getIsForTeams() {
    return selectedReport === 'pdf_for_teams';
  }

  if (loading && phase.final_rank && !totalEvaluations) return <Loading text="" />;

  return (
    <>
      <ConfirmPrintPDFForTeamsModal
        multiple={true}
        isForTeams={getIsForTeams()}
        show={confirmPrintPDFForTeamsModal}
        onCancel={handleCancelPDFForTeamsReport}
        onConfirm={getOnConfirmFunction()}
        showStatusAndRangeSelector={true}
        phaseFinalRank={phase.final_rank}
        pdfTeamRange={pdfTeamRange}
        totalEvaluations={totalEvaluations}
        pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
        handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
        setPdfTeamRange={setPdfTeamRange}
      />
      <TeamSelectorModal
        isSinglePDF={isSinglePDF}
        setIsSinglePDF={setIsSinglePDF}
        eventId={event.id}
        onCancel={handleCancelTeamFilesReport}
        onConfirm={({ teams }: { teams: OptionValue[] }) => handleConfirmTeamFilesReport(teams)}
        show={confirmPDFForJudgePanel}
        customModalTitle="PDF Results For Judge Panel"
        phaseFinalRank={phase.final_rank}
        pdfTeamRange={pdfTeamRange}
        totalEvaluations={totalEvaluations}
        pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
        handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
        setPdfTeamRange={setPdfTeamRange}
        showBoxPlot={showBoxPlot}
        setShowBoxPlot={setShowBoxPlot}
        isQualitativeMode={isQualitativeMode}
      />
      <h2>Detailed Team Results PDFs</h2>
      <ListGroup className="mb-3">
        <>
          {reportItems.map((item) => (
            <ReportsListItem
              key={item.reportName}
              reportName={item.reportName}
              title={item.title}
              description={item.description}
              onClick={(reportName) => handleClickPDFReport(reportName as PDFReportName)}
              disabled={item.disabled}
            />
          ))}
        </>
      </ListGroup>
    </>
  );
};

export default withRouter(connector(PDFReports));

import { Badge, DashboardRoleSelect, ReactSelect } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useMemo } from 'react';
import withRouter from 'routes/withRouter';

import { dashboardUrlFromUserRole } from 'utils/urls';

import Styles from '../Dashboard.module.scss';

const CustomOption = (props) => {
  const { children, data } = props;
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <ReactSelect.components.Option {...props}>
      <div className="d-flex align-items-center justify-content-between">
        {children}
        {data?.has_feedback && (
          <Badge
            className="text-decoration-none fw-normal"
            bg={data.has_unviewed_feedback ? 'success' : 'gray03'}
            pill
          >
            <small>Feedback</small>
          </Badge>
        )}
      </div>
    </ReactSelect.components.Option>
  );
};

const CustomPlaceholder = (props) => {
  const { children, options } = props;
  const roles = options.map((o) => o.options).flat();
  const hasFeedback = roles.some((option) => option.has_feedback);
  const hasUnviewedFeedback = roles.some((option) => option.has_unviewed_feedback);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <ReactSelect.components.Placeholder {...props}>
      <div className="d-flex align-items-center justify-content-between">
        {children}
        {hasFeedback && (
          <span
            className={cx('d-inline-block rounded-circle', Styles.Dot, {
              'bg-success': hasUnviewedFeedback,
              'bg-gray03': !hasUnviewedFeedback,
            })}
          >
            <small>&nbsp;</small>
          </span>
        )}
      </div>
    </ReactSelect.components.Placeholder>
  );
};

const DashboardRolesSelect = ({ event, roles, navigate }) => {
  const handleChange = (role) => {
    const url = dashboardUrlFromUserRole(role, event);
    navigate(url);
  };

  const groupedOptions = useMemo(() => {
    let options = (roles || []).map((option) => {
      const status =
        option.evaluations_status?.pending === 0
          ? 'Done'
          : `${option.evaluations_status?.completed} / ${option.evaluations_status?.total} complete`;
      let badgeContent =
        option.role_specific_name === 'Evaluator' && option.evaluations_status?.total > 0
          ? status
          : null;

      return { ...option, badgeContent };
    });

    const groupedOptions = options.reduce(
      (groups, option) => {
        groups[option.name === 'TeamDraft' ? 1 : 0].options.push(option);
        return groups;
      },
      [
        { label: 'ROLES', options: [] },
        { label: 'DRAFT PROFILES', options: [] },
      ],
    );

    return groupedOptions;
  }, [roles]);

  return (
    <DashboardRoleSelect
      onChange={handleChange}
      defaultValue=""
      options={groupedOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.role_specific_name}
      components={{ Option: CustomOption, Placeholder: CustomPlaceholder }}
    />
  );
};

export default withRouter(DashboardRolesSelect);

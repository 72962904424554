import { Button } from '@valid-eval/shared-react-components';
import { connect, ConnectedProps } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { deleteIntro } from 'data/features/intros';
import { Intro as IntroType } from 'data/features/introsTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';
import { success as showSuccess } from 'data/actions/notifications';

type IntroDeleteProps = {
  intro: IntroType;
};

const mapDispatchToProps = { deleteIntro, showSuccess };

const connector = connect(null, mapDispatchToProps);

const IntroDelete = ({
  intro,
  deleteIntro,
  showSuccess,
}: IntroDeleteProps & ConnectedProps<typeof connector>) => {
  const [showConfirmation, openConfirmation, closeConfirmation] = useBooleanFlag();
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const handleDelete = async () => {
    closeConfirmation();
    const result = showErrorOnRejectedWithValue(
      await deleteIntro({
        introId: intro.id,
        phaseId: intro.phaseId,
        teamId: intro.teamId,
      }),
    );
    if (result) showSuccess('The intro has been deleted');
    return result;
  };

  return (
    <>
      <GenericModal
        body="Please confirm that you want to delete this intro?"
        name="delete-intro"
        onCancel={closeConfirmation}
        onConfirm={handleDelete}
        show={showConfirmation}
        title="Delete intro"
        size="md"
      />
      {intro.canEdit && (
        <Button name="delete" variant="link" className="p-0" onClick={openConfirmation}>
          <i className="m-0 fa-regular fa-trash-can" />
        </Button>
      )}
    </>
  );
};

export default connector(IntroDelete);
